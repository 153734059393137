import {BasilEditOutline} from '@/assets/icons/mine/BasilEditOutline';
import PageEnum from '@/enums/pageEnum';
import {bankInfoType} from '@/pages/mine/addPaymentMethod/useStateHooks';
import {OperatingEnum, TypeEnum} from '@/pages/mine/bank/useStateHooks';
import {history} from '@@/core/history';
import {useTranslation} from 'react-i18next';
import { useRouteProps } from '@@/exports';

/**
 * 银行卡信息卡片
 */
export default (props: IProps) => {
  const {  bankInfo } = props;
  const { t } = useTranslation();
  const route: any = useRouteProps() || {};

  return (
    <>
      <div className="w-full border-1 border-backgroundAuxiliaryColor rounded-md mb-8 px-4 py-4">
        <div className="flex items-center mb-4">
          <span className="w-[6px] h-[14px] bg-primary rounded-md mr-2"></span>
          <div className="text-backContrastColor">{t('银行借记卡')}</div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className=" text-auxiliaryTextColor font-bold">
            {bankInfo?.cardHolder}
          </div>
          <div className=" text-auxiliaryTextColor">
            {bankInfo?.cardNumber}
          </div>
          <div className=" w-full text-auxiliaryTextColor flex items-center justify-between">
            <span>{t(bankInfo?.bankName)}</span>
            <BasilEditOutline
              className=" cursor-pointer text-backContrastColor"
              width="20px"
              height="20px"
              onClick={() => {
                history.push(`${PageEnum.ADD_COLLECTION}?type=${TypeEnum.BANK}&operatingType=${OperatingEnum.EDIT}&targetUrl=${route.path}&id=${bankInfo.id}`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

interface IProps {
  type: TypeEnum;
  bankInfo: bankInfoType;
}
